import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../utils/utils";

export const fetchSobreNosFirstOrDefault = createAsyncThunk(
  "sobreNos/fetchSobreNosEdit",
  async (data, thunkAPI) => {
    const url =
      process.env.REACT_APP_API_URL + "/api/sobreNos/getFirstOrDefault";
    const options = {
      method: "get",
      headers: {
        Authorization: "bearer " + getToken(),
      },
      url,
    };
    const response = await axios(options)
      .then((result) => {
        if (result.data) {
          return result.data;
        } else {
          return "Error";
        }
      })
      .catch((error) => {
        return "Error:" + error?.response?.data?.ExceptionMessage;
      });

    return response;
  }
);

export const fetchSobreNos = createAsyncThunk(
  "sobreNos/fetchSobreNos",
  async (data, thunkAPI) => {
    const url = process.env.REACT_APP_API_URL + "/api/sobreNos";
    const options = {
      method: "GET",
      headers: {
        Authorization: "bearer " + getToken(),
      },
      data,
      url,
    };
    const response = await axios(options)
      .then((result) => {
        if (result.data) {
          // sessionStorage.setItem("token", result.data.access_token);
          return result.data;
        }
      })
      .catch((error) => {
        return error.code;
      });

    return response;
  }
);

export const fetchSobreNosSearch = createAsyncThunk(
  "sobreNos/fetchSobreNosSearch",
  async (data, thunkAPI) => {
    const url =
      process.env.REACT_APP_API_URL + "/api/sobreNos/search?search=" + data;
    const options = {
      method: "get",
      headers: {
        Authorization: "bearer " + getToken(),
      },
      url,
    };
    const response = await axios(options)
      .then((result) => {
        if (result.data) {
          return result.data;
        } else {
          return "Error";
        }
      })
      .catch((error) => {
        return "Error:" + error?.response?.data?.ExceptionMessage;
      });

    return response;
  }
);

export const fetchSobreNosEdit = createAsyncThunk(
  "sobreNos/fetchSobreNosEdit",
  async (data, thunkAPI) => {
    const url =
      process.env.REACT_APP_API_URL + "/api/sobreNos/getByID?id=" + data.ID;
    const options = {
      method: "get",
      headers: {
        Authorization: "bearer " + getToken(),
      },
      url,
    };
    const response = await axios(options)
      .then((result) => {
        if (result.data) {
          return result.data;
        } else {
          return "Error";
        }
      })
      .catch((error) => {
        return "Error:" + error?.response?.data?.ExceptionMessage;
      });

    return response;
  }
);

export const saveSobreNos = createAsyncThunk(
  "sobreNos/saveSobreNos",
  async (data, thunkAPI) => {
    const url = process.env.REACT_APP_API_URL + "/api/sobreNos";

    const options = {
      method: "post",
      headers: {
        Authorization: "bearer " + getToken(),
        "Content-Type": "multipart/form-data",
      },
      url,
      data: data,
    };
    const response = await axios(options)
      .then((result) => {
        if (result.data) {
          return result.data;
        } else {
          return "Error";
        }
      })
      .catch((error) => {
        return "Error:" + error?.response?.data?.ExceptionMessage;
      });

    return response;
  }
);

export const editSobreNos = createAsyncThunk(
  "sobreNos/editSobreNos",
  async (data, thunkAPI) => {
    const url = process.env.REACT_APP_API_URL + "/api/sobreNos/" + data.ID;

    const options = {
      method: "put",
      headers: {
        Authorization: "bearer " + getToken(),
        "Content-Type": "multipart/form-data",
      },
      url,
      data: data,
    };
    const response = await axios(options)
      .then((result) => {
        return result.status;
      })
      .catch((error) => {
        return "Error:" + error?.response?.data?.ExceptionMessage;
      });

    return response;
  }
);

export const deleteSobreNos = createAsyncThunk(
  "sobreNos/deleteSobreNos",
  async (id, thunkAPI) => {
    const url = process.env.REACT_APP_API_URL + "/api/sobreNos/" + id;

    const options = {
      method: "delete",
      headers: { Authorization: "bearer " + getToken() },
      url,
    };
    const response = await axios(options)
      .then((result) => {
        return result.status;
      })
      .catch((error) => {
        return "Error:" + error?.response?.data?.ExceptionMessage;
      });

    return response;
  }
);
