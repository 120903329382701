import moment from "moment";

export const getToken = () => {
  return sessionStorage.getItem("accessToken");
};

export const getUserID = () => {
  return sessionStorage.getItem("id");
};

export const getMomentFormatDateTime = (value) => {
  return value !== ""
    ? moment(value)
    : moment().format("YYYY-MM-DD HH:mm:ss").replace(" ", "T") + "Z";
};

export const checkError = (error) => {
  console.log("deu erro: ", error);
  switch (error) {
    case "Error:001":
      return "/dashboard/home/invalidAccess";
    case "Error:undefined":
      sessionStorage.removeItem("token");
      return "/";
    default:
      return "*";
  }
};

export const getVideoId = (url) => {
  try {
    const urlObj = new URL(url);
    const videoId = urlObj.pathname.split("/").pop();
    const searchParams = urlObj.search;
    return videoId + searchParams;
  } catch (error) {
    console.error("Invalid URL:", error);
    return null;
  }
};
