import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilBell,
  cilCalculator,
  cilChartPie,
  cilCursor,
  cilDescription,
  cilDrop,
  cilNotes,
  cilPencil,
  cilPuzzle,
  cilSpeedometer,
  cilStar,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";

const _nav = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: "info",
      text: "NEW",
    },
  },
  {
    component: CNavTitle,
    name: "Sistema",
  },
  {
    component: CNavGroup,
    name: "Acesso",
    to: "/dashboard/pages",
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Usuários",
        to: "dashboard/pages/usuarios",
      },
      {
        component: CNavItem,
        name: "Tipo de Usuários",
        to: "dashboard/pages/tipo_de_usuarios",
      },
    ],
  },
  {
    component: CNavTitle,
    name: "Associação",
  },
  {
    component: CNavItem,
    name: "Associados",
    to: "dashboard/pages/associados",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Balanço Financeiro",
    to: "dashboard/pages/balanco_financeiro",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: "Conteúdo",
  },
  {
    component: CNavGroup,
    name: "Home",
    to: "/dashboard/",
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Home",
        to: "home/home",
      },
      {
        component: CNavItem,
        name: "Mini Titulo",
        to: "home/mini_titulo",
      },
    ],
  },
  // {
  //   component: CNavItem,
  //   name: "Home",
  //   to: "pages/home",
  //   icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
  // },
  {
    component: CNavGroup,
    name: "Sobre nós",
    to: "/base",
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Sobre Nós",
        to: "sobre_nos/sobre_nos",
      },
      {
        component: CNavItem,
        name: "Prestação de Contas",
        to: "pages/home",
      },
      {
        component: CNavItem,
        name: "Imóveis",
        to: "pages/type_users",
      },
      {
        component: CNavItem,
        name: "Projetos e Parceiros",
        to: "pages/type_users",
      },
      {
        component: CNavItem,
        name: "Colaboradores",
        to: "pages/type_users",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Informativo",
    to: "/base",
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Notícias",
        to: "pages/home",
      },
      {
        component: CNavItem,
        name: "Jornal da AMCATD",
        to: "pages/type_users",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Eventos",
    to: "/base",
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Eventos",
        to: "base/accordion",
      },
      {
        component: CNavItem,
        name: "Reservas de Espaço",
        to: "/base/breadcrumbs",
      },
      {
        component: CNavItem,
        name: "Fotos",
        to: "/base/breadcrumbs",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Serviços",
    to: "/base",
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Mão de obra",
        to: "base/accordion",
      },
      {
        component: CNavItem,
        name: "Lojas",
        to: "/base/breadcrumbs",
      },
    ],
  },
  {
    component: CNavItem,
    name: "Contato",
    to: "theme/colors",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Login",
    to: "theme/colors",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
  },
];

export default _nav;
